<template>
  <div>
    <van-image width="34" :src="url" @click="() => {return;show = true}" />
    <van-dialog v-model="show" :show-confirm-button="false" close-on-click-overlay>
      <van-grid :column-num="1" :border="false">
        <van-grid-item v-for="item,index in lans" :key="index">
          <div class="grid_item" :class="item.type == country ? 'check':''" @click="onSelect(item)">
            <van-image width="34" :src="item.icon" />
            <span>{{ item.name }}</span>
          </div>
        </van-grid-item>
      </van-grid>
      <!-- <van-action-sheet v-model="modalShow" :actions="lans" @close="onClose" @select="onSelect" /> -->
    </van-dialog>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { Locale, Dialog, Grid, GridItem } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
import zhCN from "@/i18n/zh.js";
import thTH from "@/i18n/th.js";
import trTR from "@/i18n/tr.js";
import { ActionSheet, Image as VanImage } from 'vant';
export default {
  components: {
    [ActionSheet.name]: ActionSheet,
    VanImage,
    [Dialog.Component.name]: Dialog.Component,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem
  },
  data() {
    return {
      lans: [],
      show: false,
      country: '',
      modalShow: true
    }
  },
  computed: {
    url() {
      let index ='';
      index = this.lans.findIndex((item) => item.type == this.country);
      if(index < 0){
        this.country = this.verConfig.lan || '';
        index = this.lans.findIndex((item) => item.type == this.country);
        this.$i18n.locale = this.country;
          Locale.use("en-US", enUS);
          Cookies.set("language", this.country);
          localStorage.setItem("language", this.country);
      }
      return this.lans[index]?.icon || ''
    }
  },
  created() {
    this.lans = this.verConfig.lans;
    this.country = localStorage.getItem('language') || this.verConfig.lan;

  },
  methods: {
    onClose() {
      this.show = false;
    },
    onSelect(e) {
      this.country = e.type;
      switch (e.type) {
        case "en":
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
        case "idn":
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
        case "zh":
          this.$i18n.locale = e.type;
          Locale.use("zh-CN", zhCN);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
        case "th":
          this.$i18n.locale = e.type;
          Locale.use("th-TH", thTH);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
        case "tr":
          this.$i18n.locale = e.type;
          Locale.use("tr-TR", trTR);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
      }
      this.show = false;
    }
  }
}
</script>
<style scoped lang="less">
/deep/ .van-dialog{
  width: 90%;
}
/deep/ .van-dialog__content{
  padding: calc(24rem / 16);
  min-height: calc(120rem / 16);
}
/deep/ .van-grid-item__content{
  align-items: normal;
  padding: 0;
}
.grid_item{
  border-bottom: 1px solid rgba(0, 0, 0,.12);
  padding: calc(8rem / 16) calc(16rem / 16);
  display: flex;
  align-items: center;
  font-size: calc(14rem / 16);
  color: #000;
  span{
    margin-left: calc(16rem / 16);
  }
}
.check{
  background-color: #bdbdbd;
}
</style>